// src/App.js
import React, { useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './App.css';
import Header from "./components/Header"
import Contacts from './components/Contacts';
import Home from "./components/Home"
import About from './components/About';
import "./bootstrap-5.3.3/bootstrap-5.3.3/dist/css/bootstrap.min.css"
import "./bootstrap-5.3.3/bootstrap-5.3.3/dist/js/bootstrap.bundle.min"
import Responsibilities from './components/Responsibilities';

function App() {
  
  return (
    <div className="background">
      
      <Header />


      {/* Sections */}
      <div id="home" className="section">
        
        <Home/>
        {/* <p>Welcome to our website!</p> */}
        
      </div>

      <div id="about" className="col-md-12 section">
        
        <About/>
        {/* <p>We are a company dedicated to providing the best responsibilities.</p> */}
      </div>

      
        
        <Responsibilities/>
        {/* <p>We offer a wide range of responsibilities to meet your needs.</p> */}
      
      {/* contacts section */}
      <Contacts/>
      
     
      
    </div>
  );
  
}

export default App;
