import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useEffect, useRef } from 'react';
import './About.css';
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/css/bootstrap.min.css"
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/js/bootstrap.bundle.min"

function About(){


   


    
  // Function to animate the numbers
  const animateNumber = (element, start, end, duration) => {
    let range = end - start;
    let current = start;
    let increment = range / (duration / 10); // Faster increment
    let stepTime = 10; // Faster step time
    let timer = setInterval(() => {
      current += increment;
      if (current >= end) {
        current = end;
        clearInterval(timer);
      }
      element.textContent = Math.round(current);
    }, stepTime);
  };

  useEffect(() => {
        const entriesNumber = document.getElementById('entries-number');
        const entriesNumber2 = document.getElementById('entries-number2');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateNumber(entriesNumber, 1, 250, 2000);
            animateNumber(entriesNumber2, 1, 200, 2000);
          
            observer.unobserve(entry.target); // Stop observing once animated
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observing the first element (others will trigger together)
    if (entriesNumber) {
      observer.observe(entriesNumber);
    }

    // Cleanup observer on component unmount
    return () => {
      if (entriesNumber) observer.unobserve(entriesNumber);
    };
  }, []);
 return (
    <div className='col-md-12 about-section'>
        <div className='about-heading'>
        </div>
        <div className='col-md-6 col-12 about-content'>
            
            <div className='about-content-inner'>
                
                    <p>The Campus Ambassador Program for Aarohi is an initiative to engage dynamic 
                    students as representatives of the event. Ambassadors will promote Aarohi in their 
                    respective colleges, helping to spread awareness, increase participation, and create 
                    excitement around the fest. In return, ambassadors gain valuable leadership experience, 
                    exclusive perks, and networking opportunities while being part of one of the most celebrated 
                    cultural events on campus.</p>
                
            </div>
        </div>
        <div className='col-md-6 col-12 space'>
            <div className='col-md-6 col-12 about-content'>
                
                <div className='about-content-inner'>
                    
                        <p> 
                          Aarohi is an annual cultural fest celebrated at our college, 
                          showcasing a vibrant fusion of music, dance, art, and creativity. 
                          It brings together students from diverse backgrounds to participate in 
                          competitions, performances, and workshops, fostering a sense of community and 
                          cultural exchange. Aarohi serves as a platform for students to express their talents, 
                          build lasting memories, and experience a thrilling blend of tradition and innovation.
                        </p>
                    
                </div>
            </div>
        </div>
        <div className="bottom-container">
            <div className="box">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="90" height="90" viewBox="0 0 171 171" style={{fill: "#000000"}}>
                    <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}>
                        <path d="M0,171.99637v-171.99637h171.99637v171.99637z" fill="none"></path>
                        <g fill="#f8a728">
                        <path d="M85.5,17.1c-18.8898,0 -34.2,15.3102 -34.2,34.2v5.7c0,18.8898 15.3102,34.2 34.2,34.2c18.8898,0 34.2,-15.3102 34.2,-34.2v-5.7c0,-18.8898 -15.3102,-34.2 -34.2,-34.2zM85.48887,108.3c-22.8342,0 -52.1322,12.35101 -60.5625,23.31211c-5.2098,6.7773 -0.25035,16.58789 8.29395,16.58789h104.54824c8.5443,0 13.50374,-9.81059 8.29394,-16.58789c-8.4303,-10.9554 -37.73943,-23.31211 -60.57363,-23.31211z"></path>
                        </g>
                    </g>
                </svg>
                <p className="main-info">
                    <span id="entries-number">1000</span>+
                </p>
                <p className="info">Ambassadors</p>
            </div>
            <div className="box">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="90" height="90" viewBox="0 0 171 171" style={{fill: "#000000"}}>
                <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}>
                    <path d="M0,171.99637v-171.99637h171.99637v171.99637z" fill="none"></path>
                    <g fill="#f8a728">
                    <path d="M85.5,17.1c-0.94031,0.00068 -1.86585,0.23398 -2.69414,0.6791l-79.74434,39.8666v0.01113c-1.87878,0.9811 -3.05803,2.92365 -3.06152,5.04317c0.0035,2.11952 1.18275,4.06206 3.06152,5.04317v0.01113l79.71094,39.84433c0.8362,0.45825 1.77401,0.6994 2.72754,0.70137c0.94031,-0.00068 1.86586,-0.23398 2.69414,-0.6791l79.68867,-39.84433c0.01859,-0.00732 0.03715,-0.01475 0.05567,-0.02226v-0.01113c1.87877,-0.9811 3.05802,-2.92365 3.06152,-5.04317c-0.0035,-2.11952 -1.18275,-4.06206 -3.06152,-5.04317v-0.01113l-79.71094,-39.84433c-0.8362,-0.45825 -1.77401,-0.6994 -2.72754,-0.70137zM165.3,81.81504l-11.4,5.7v50.86582c-3.39511,1.97373 -5.7,5.60715 -5.7,9.81914c0,6.2985 11.4,22.8 11.4,22.8c0,0 11.4,-16.5015 11.4,-22.8c0,-4.21199 -2.30489,-7.84541 -5.7,-9.81914zM34.2,96.0539v23.6461c0,15.7377 22.9653,28.5 51.3,28.5c28.3347,0 51.3,-12.7623 51.3,-28.5v-23.6461l-43.51817,21.76465c-0.4788,0.2394 -0.95706,0.43587 -1.44726,0.60117c-2.0634,0.8493 -4.18567,1.28027 -6.33457,1.28027c-2.1432,0 -4.26031,-0.43097 -6.31231,-1.28027c-0.4959,-0.1653 -0.98503,-0.36721 -1.46953,-0.61231z"></path>
                    </g>
                </g>
                </svg>
                
                <p className="main-info">
                    <span id="entries-number2">1000</span>+
                </p>
                <p className="info">Institutions</p>
            </div>
            
        </div>
    </div>
 )   
}

export default About;