import React from 'react';

import organiseImg from '../images/organise.png';
import publicityImg from '../images/publicity.png';
import promoteImg from '../images/promote.png';
import socialMediaImg from '../images/social_media.png';
import './Responsibilities.css';
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/css/bootstrap.min.css"
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/js/bootstrap.bundle.min"

function Responsibilities(){
    return(
        <div id="responsibilities">
            <div className='responsibilities-heading'><h1>What will you do?</h1></div>
            <div className="content-wrap">
            <div className="wait1" 
                 style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: '1 1 0%',
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                    transition: 'all, opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.25s, transform 0.6s cubic-bezier(0.5, 0, 0, 1) 0.25s'
                 }}
                 data-sr-id="20">
                <img src={organiseImg} alt="Organize" className="image2" />
                <div className="p-3 text-center rounded  contentbox">
                    <h3 className="mt-3 name">Organize</h3>
                    <p>
                    Organizing cultural and artistic activities in your college to ignite excitement and enthusiasm for Aarohi among your peers.
                    </p>
                </div>
            </div>

            <div className="wait2" 
                 style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: '1 1 0%',
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                    transition: 'all, opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.5s, transform 0.6s cubic-bezier(0.5, 0, 0, 1) 0.5s'
                 }}
                 data-sr-id="21">
                <img src={publicityImg} alt="Publicity" className="image2" />
                <div className="p-3 text-center rounded  contentbox">
                    <h3 className="mt-3 name">Publicity</h3>
                    <p>
                    Managing Aarohi's public image by engaging in promotional efforts like putting up posters, 
                    coordinating with student bodies, and organizing pre-event activities.
                    </p>
                </div>
            </div>

            <div className="wait3" 
                 style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: '1 1 0%',
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                    transition: 'all, opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0.75s, transform 0.6s cubic-bezier(0.5, 0, 0, 1) 0.75s'
                 }}
                 data-sr-id="22">
                <img src={promoteImg} alt="Promote" className="image2" />
                <div className="p-3 text-center rounded  contentbox">
                    <h3 className="mt-3 name">Promote</h3>
                    <p>
                    Identifying passionate and enthusiastic individuals from your college to actively participate 
                    in Aarohi's diverse events and competitions.
                    </p>
                </div>
            </div>

            <div className="wait4" 
                 style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: '1 1 0%',
                    visibility: 'visible',
                    opacity: 1,
                    transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                    transition: 'all, opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 1s, transform 0.6s cubic-bezier(0.5, 0, 0, 1) 1s'
                 }}
                 data-sr-id="23">
                <img src={socialMediaImg} alt="Social Media" className="image2" />
                <div className="p-3 text-center rounded  contentbox">
                    <h3 className="mt-3 name">Social Media</h3>
                    <p>
                    Leveraging social media platforms such as Instagram, Facebook, and WhatsApp to share
                    e-posters and updates, while also disseminating information via emails and college communication channels.
                    </p>
                </div>
            </div>
            </div>
        </div>
    )
}
export default Responsibilities;