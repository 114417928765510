import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/css/bootstrap.min.css"
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/js/bootstrap.bundle.min"
import "./Contacts.css"

function Contacts(){
    return(
        <footer id="contact" className="contacts-background">
        <div className="contacts-logo">
            <div className="aarohiLogo"></div>
            <div className="contacts-info">Aarohi is the annual cultural festival of Visvesvaraya National Institute of Technology (VNIT), Nagpur. It's one of the largest cultural fests in central India, attracting students from various colleges across the country. The event typically features a wide range of activities, including cultural competitions, workshops, guest lectures, exhibitions, and paper presentations.</div>
        </div>
        <div className="contacts-writing">
            <div className="quick-links">
                <div className="quick-links-heading">QUICK LINKS</div>
                <div className="quick-links-content">
                    <div>
                        <Link className="nav-link" to="home" smooth={true} duration={500}>
                            HOME
                        </Link>
                    </div>
                    <div>
                        <Link className="nav-link" to="about" smooth={true} duration={500}>
                            ABOUT
                        </Link>
                    </div>
                    <div>
                        <Link className="nav-link" to="responsibilities" smooth={true} duration={500}>
                            RESPONSIBILITIES
                        </Link>
                    </div>
                    <div>
                        <Link className="nav-link" to="contact" smooth={true} duration={500}>
                            CONTACTS
                        </Link>
                    </div>
                    <div>
                        <a className="nav-link" href="https://docs.google.com/forms/d/e/1FAIpQLSe66F4qovZXqP8MfH2fNu47bVxLPytIDGBmuYn_DHufJL7akg/viewform?usp=sf_link"
                        target = "_blank"
                        rel = "noopener noreferrer" >
                            APPLY NOW
                        </a>
                    </div>
                    <div>
                        <a className="nav-link" href="/ruleBook.pdf" download>
                            RULES
                        </a>
                    </div>

                </div>
                
            </div>

            <div className="reach-out">
                <div className="reach-out-heading">REACH OUT</div>
                <div className="reach-out-address"> Visvesvaraya National Institute of Technology Nagpur
                    South Ambazari Road, Nagpur, Maharashtra.</div>
            </div>

            <div className="contact">
                <div className="contact-heading">CONTACT</div>
                <div className="contact-numbers">
                    Harsh: +91 8446552403<br/><br/>
                    Utsavi: +91 9924128400<br/><br/>
                    Ishant: +91 8080017529<br/><br/>
                    Hardik: +91 9867239393<br/><br/>
                </div>
            </div>

            <div className="follow-us">
                <div className="follow-us-heading">FOLLOW US</div>
                <div className="social-icons">
                    <a className='insta-logo' href="https://www.instagram.com/aarohi_vnitnagpur/?hl=en" target="_blank"></a>
                    <a className='facebook-logo' href="https://www.facebook.com/AarohiWorld/" target="_blank"></a>
                    <a className='youtube-logo' href="https://www.youtube.com/@VNITAarohi" target="_blank"></a>
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Contacts;