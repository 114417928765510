import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import '../App.css';
import './Home.css';
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/css/bootstrap.min.css"
import "../bootstrap-5.3.3/bootstrap-5.3.3/dist/js/bootstrap.bundle.min"


function Home(){
    return(
        <div className='container'>
            <div className='slide-in-left uni-name col-12'>
                <h1>Aarohi , VNIT Nagpur</h1>
            </div>
            <div className='slide-in-left event-name col-12'>
                <h2>Campus Ambassador Program</h2>
            </div>
            <div className='row col-md-6'>
                <div className='col-md-6'>
                <a className='registration-form' href='https://docs.google.com/forms/d/e/1FAIpQLSe66F4qovZXqP8MfH2fNu47bVxLPytIDGBmuYn_DHufJL7akg/viewform?usp=sf_link'
                target = "_blank"
                rel = "noopener noreferrer">
                <div className='slide-in-left apply-now '>Apply now</div>
                </a> 
                </div>
                <div className='col-md-6'>
                <a className='registration-form' href="/ruleBook.pdf" download>
                <div className='slide-in-left apply-now '>Know More</div>    
            </a> 
                </div>

            </div>
                 
              
            
        </div>
    )
}

export default Home;